import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header/header";

function App() {
  const form = useRef();
  const [name, setName] = useState("");
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // Check if backend server is reachable
    const checkServerStatus = async () => {
      try {
        const response = await fetch("https://millharbour-backend-1.onrender.com/");
        if (response.ok) {
          setIsConnected(true);
        }
      } catch (error) {
        console.error("Error connecting to backend server:", error);
        setIsConnected(false);
      }
    };

    checkServerStatus();
  }, []); 

  // post request to add customer details to the database
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      gender,
      email,
      phone,
      message,
    };

    try {
      const response = await fetch("https://millharbour-backend-1.onrender.com/", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("New example created:", data);
      form.current.reset();
      notify("Message Sent");
    } catch (error) {
      console.error("Error creating example:", error);
      toastError("Failed to send message");
    }
  };

  const testimonials = [
    {
      name: "Tom K",
      testimonial: "Lovely stuff",
    },
    {
      name: "Bob Jones",
      testimonial: "Hassle free match betting.",
    },
    {
      name: "John Smith",
      testimonial: "Easy money",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  // Reference to the React Slick slider
  const sliderRef = useRef(null);

  // Function to go to the previous slide
  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  // Function to go to the next slide
  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const toastError = () => {
    toast.error("Failed to send message");
  };

  const notify = () => {
    toast.success("Message Sent");
  };

  return (
    <div className="App" id="homeSection">
      <Header />
      <div className="logo-div">
        <img src="/images/mill-harbour.jpeg" className="logo" alt="logo" />
      </div>
      <div className="text-div">
        <p className="headline">
          Join 100s of successful members making quick and easy income without
          the fuss of match betting.
        </p>
      </div>
      <div className="testimonials">
        <h2 className="testimonial-title">Testimonials</h2>
        <p className="testimonial-subtitle">See what our members say</p>
      </div>
      <div className="slider-container">
        <span onClick={goToPrevSlide}>
          <img
            src="https://img.icons8.com/material-rounded/30/ffffff/chevron-left.png"
            alt="prev"
            className="prev"
          />
        </span>
        <Slider {...settings} className="slider" ref={sliderRef}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <span className="quote-icon">
                <BiSolidQuoteAltLeft />
              </span>
              <p className="testimonial-text">{testimonial.testimonial}</p>
            </div>
          ))}
        </Slider>
        <span onClick={goToNextSlide}>
          <img
            src="https://img.icons8.com/material-rounded/30/ffffff/chevron-right.png"
            alt="next"
            className="next"
          />
        </span>
      </div>
      <div className="about" id="aboutSection">
        <h2 className="about-title">About us</h2>
        <p className="about-text">
          Mill Harbour operates in the sports trading industry, leveraging data
          analysis and cutting-edge technology to create accurate match
          predictions and capitalise on market inefficiencies. In 2023, we
          achieved a turnover of over 8 figures, and with your help, we aim to
          significantly increase this going forward. Founded by members of the
          Bristol University football society, Mill Harbour understands the
          financial challenges university students and societies face. Our
          firsthand experience drives us to support university students and
          societies through innovative sponsorship opportunities.
        </p>
      </div>
      <div className="how-it-works" id="howSection">
        <h2 className="about-title">How it Works</h2>
        <ul className="how-text">
          <li>
            <span className="number">1.</span> Provide the necessary details to
            open a sports trading account (or use an existing one)
          </li>
          <li>
            <span className="number">2.</span> Sign a legally binding contract
          </li>
          <li>
            <span className="number">3.</span> We will send you the required
            funds for you to deposit into the account
          </li>
          <li>
            <span className="number">4.</span> Over 90 days or less, we trade
            using this account. You keep a minimum of 5% of any profits made
            from the account. If no profits are made you will still be
            guaranteed £100 (+£75 for society if sponsorship has been previously
            agreed)
          </li>
          <li>
            <span className="number">5.</span> We will then withdraw the
            winnings and you transfer them to us, freeing your account for
            personal use.
          </li>
        </ul>
      </div>
      <div className="contact" id="contactSection">
      <h2 className="contact-title">Contact us</h2>
      {!isConnected ? (
        <h2 className='loading'>Please wait while we connect to our server...</h2>
      ) : (
        <form ref={form} onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
          <div className="form-container">
            <label className="contact-name">
              Name<span className="required">*</span>
            </label>
            <input
              className="user-name"
              type="text"
              name="user_name"
              placeholder="Your Full Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label className="contact-email">
              Gender<span className="required">*</span>
            </label>
            <select
              className="gender"
              name="gender"
              placeholder="Select"
              onChange={(e) => setGender(e.target.value)}
              value={gender}
              required
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </select>
            <label className="contact-email">
              Email<span className="required">*</span>
            </label>
            <input
              className="user-email"
              type="email"
              name="user_email"
              placeholder="Your Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label className="contact-message">
              Phone Number<span className="required">*</span>
            </label>
            <input
              className="user-phone"
              type="phone"
              name="phone"
              placeholder="Your Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <label className="contact-message">
              Message<span className="required">*</span>
            </label>
            <textarea
              className="user-message"
              name="message"
              placeholder="Please tell us why you want to use our services"
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <input className="contact-send" type="submit" value="Send" />
          </div>
        </form>
      )}
      <ToastContainer />
      <div className="copy-rights">
        <p className="copy-right">
          © 2024 Mill Harbour. All rights reserved.
        </p>
      </div>
    </div>
  </div>
)
};

export default App;
