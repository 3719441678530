import React from 'react';
import './header.css';

function Header () {

// Scroll to a section
const scrollToSection = (sectionId) => {
    // Get the section
    const section = document.getElementById(sectionId);
    // If the section exists, scroll to it
    if (section) {
      // Get the header element
      const header = document.querySelector('.Header');
  
      // Set the offset to the height of the header
      let offset = 0;
  
      // Check if the header exists and has a height
      if (header && header.offsetHeight) {
        offset = header.offsetHeight;
      }
  
      // Get the top position of the section by offsetting the section's top position by the offset
      const top = section.offsetTop - offset;
      // Scroll to the desired position
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

    // Handle the click event for the "Home" button
    const homeHandleClick = () => {
        // Scroll to the "Home" section
        scrollToSection('homeSection');
      };
    
      // Handle the click event for the "About" button
      const aboutHandleClick = () => {
        // Scroll to the "about" section
        scrollToSection('aboutSection');
      };
    
      // Handle the click event for the "How it Works" button
      const howHandleClick = () => {
        // Scroll to the "How it Works" section
        scrollToSection('howSection');
      };
    
      // Handle the click event for the "Contact" button
      const contactHandleClick = () => {
        // Scroll to the "Contact" section
        scrollToSection('contactSection');
      };

    return (
        <div className="Header">
             <ul className="headerBtnList">
          <li className="headerBtn" onClick={homeHandleClick}>Home</li>
         
          <li className="headerBtn" onClick={aboutHandleClick}>About</li>
          <li className="headerBtn" onClick={howHandleClick}>How it Works</li>
         
          
          <li className="headerBtn" onClick={contactHandleClick}>Contact</li>
        </ul>
        </div>
    )
}

export default Header